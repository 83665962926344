@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish&display=swap");

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--font-theme);
  -webkit-font-smoothing: antialiased !important;
  scroll-behavior: smooth;
}

:root {
  --text-white: #fff;
  --theame-color: #212529;
  --content-font: #495057;
  --heading-font-color: #212529;
  --subheading-font-color: #343A40;
  --secondary-color: #E9ECEF;
  --facebook-icon-color: #3b5999;
  --font-theme: "Mulish", sans-serif;
  --out-of-stock-color: #000000a1;
  --out-of-stock-btn-bg-color: #f5f6f7;
  --out-of-stock-border-color: #808080;
  --footer-bg-color: #6c757d !important;
  --btn-bg-color: #6c757d !important;
}



h1 {
  font-size: 40px !important;
}

h2 {
  font-size: 25px !important;
  font-weight: 600 !important;
}

h3 {
  font-size: 22px !important;
  font-weight: 500 !important;
}

h4 {
  font-size: 19px !important;
  font-weight: 500 !important;
  font-style: italic !important;
}

h5 {
  font-size: 19px !important;
  color: var(--subheading-font-color) !important;
}

p,
h6,
a {
  font-size: 18px !important;
}

/* 
p {
  font-size: 16px !important;
} */

.common-border-radious {
  border-radius: 10px !important;
}

/* Craosuel arrow for product detail  */
.product-small-img-craousel-arrow .react-multiple-carousel__arrow--left,
.product-small-img-craousel-arrow .react-multiple-carousel__arrow--right {
  display: none !important;
}

@media (min-width:992px) {
  .navbar-menu-list a {
    font-size: 18px !important;
    font-weight: 500 !important;
  }
}

/* For Phone view */
@media (max-width:500px) {
  h2 {
    font-size: 18px !important;
    font-weight: 600 !important;
    padding-bottom: 0 !important;
    margin-bottom: 6px !important;
  }

  h3 {
    font-size: 18px !important;
    padding-bottom: 0.4rem !important;
  }

  h4 {
    font-size: 16px !important;
    font-weight: 500 !important;
    font-style: italic !important;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }

  p,
  a,
  .footer-year-font-size {
    font-size: 15px !important;
  }

  .footer-img-size {
    width: 40% !important;
  }

  .footer-social-icon ul li a {
    width: 40px !important;
    height: 40px !important;
    background-color: var(--text-white);
    font-size: 23px !important;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    border: 3px solid var(--secondary-color);
    z-index: 1;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .product-image {
    height: 100% !important;
  }

  .phone-view-dots {
    /* margin-bottom: 25px !important; */
  }
}

@media (min-width:1000px) {
  .homepro-name {
    height: 400px;
  }

  .homepro-name img {
    object-fit: cover;
  }
}

@media (min-width:767px) and (max-width:1000px) {
  .homepro-name {
    height: 300px;
  }

  .homepro-name img {
    object-fit: cover;
  }
}

@media (max-width:767px) {
  .homepro-name {
    height: 300px;
  }
}

@media (max-width:600px) {
  .homepro-name {
    height: 220px;
  }
}

@media (max-width:480px) {
  .homepro-name {
    height: 170px;
  }

  .homepro-name img {
    object-fit: cover !important;
  }

  .sorting-option,
  .total-products {
    font-size: 13px !important;
  }
}

@media (max-width:380px) {
  .homepro-name {
    height: 140px;
  }

  .homepro-name img {
    object-fit: cover !important;
  }

  .sorting-option,
  .total-products {
    font-size: 13px !important;
  }

  h6 {
    font-size: 14px !important;
  }

  .home-product-shop-now {
    padding: 5px 10px !important;
  }

  .home-product-shop-now p {
    font-size: 13px !important;
    padding: 0px 7px !important;
  }

  .testimonial-two-img1 {
    height: 400px !important;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 10px !important;
  }

  .category-dot-tab {
    padding-bottom: 0 !important;
  }

  .footer-year-font-size,
  .copy-right-foiter {
    font-size: 13px !important;
  }
}

@media (max-width:992px) {
  .phone-view-email ul li a {
    color: var(--color-dark) !important;
  }

  .phone-view-email ul li a .icon {
    width: 40px;
    height: 40px;
    background-color: var(--text-white);
    font-size: 30px !important;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    border: 3px solid var(--secondary-color);
    z-index: 1;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--theame-color);
    padding: 6px;
  }

  p,
  a {
    font-size: 15px !important;
  }

  .footer-year-font-size {
    font-size: 15px;
  }


  .footer-social-icon ul li a {
    width: 40px !important;
    height: 40px !important;
    background-color: var(--text-white);
    font-size: 23px !important;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    border: 3px solid var(--secondary-color);
    z-index: 1;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .navbar-menu-list a {
    font-size: 16px !important;
    font-weight: 500 !important;
    padding-bottom: 0.1rem !important;
  }

  .side-follow-us {
    font-size: 18px !important;
  }
}

.home-category {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

@media (max-width:992px) and (min-width:768px) {
  .footer-img-size {
    width: 85% !important;
  }
}

@media (max-width:768px) and (min-width:500px) {
  .footer-img-size {
    width: 40% !important;
  }
}

@media (max-width:1020px) and (min-width:500px) {
  .category-dot-tab {
    padding-bottom: 35px !important;
  }

  .category-tab-image-space {
    margin: 3px !important;
  }

  .product-image {
    height: 100% !important;
  }

  .carousel-container {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

@media (max-width:767.9px) {
  .copy-right-foiter {
    border-top: none !important;
  }
}

/* **** Profile page add address **** */
@media (max-width:355px) {
  .address-offcanvas {
    width: 95% !important;
  }

}

._quantity-wrapper .test {
  /* width: 30px; */
  width: 35px !important;
  border-radius: 8px;
  /* border-color: darkgrey; */
  line-height: 0;
  height: 30px;
}

/* Shop card page mobile view */
@media (max-width:500px) {
  .card-size-details-page {
    height: 140px !important;
  }

  .category-name {
    font-size: 13px !important;
  }

  ._quantity-wrapper .test {
    width: 40px !important;
    border-radius: 8px;
    border-color: darkgrey;
    line-height: 0;
    height: 25px !important;
  }

  .out-of-stock-btn,
  .shop-btn {
    font-size: 14px !important;
  }
}

@media (max-width:340px) {
  .shop-item-heading {
    font-size: 14px !important;
  }

  .card-size-details-page {
    height: 100px !important;
  }

  .out-of-stock-btn,
  .shop-btn {
    font-size: 13px !important;
  }

  .quantity-checkout {
    position: relative;
    height: 28px;
    display: flex;
    width: 80px !important;
  }

  .category-name {
    font-size: 12px !important;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .shop-card-price-size {
    font-size: 13px !important;
  }
}

.nav-serach-item-img {
  width: 80px;
  height: 60px;
  margin-right: 10px;
}

/* Login Page */
.login-modal-icon {
  font-size: 3rem;
  text-align: center;
}

.lef-rigth-border {
  position: relative;
}

.lef-rigth-border::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 45%;
  transform: translateY(-50%);
  height: 1px;
  background: #777;
}

.lef-rigth-border::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  width: 45%;
  transform: translateY(-50%);
  height: 1px;
  background: #777;
}

.login-model-signup-color {
  color: #221f1f !important;
}

.no-data-img {
  width: 100px;
}


/* new changes  */

._quantity-wrapper .quantity__button {
  background: transparent !important;
  border: none !important;
  color: currentColor;


}

.checkout-delete {
  align-items: center;
  align-self: stretch;
  background: #6c757d;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 1.5rem;
  justify-content: center;
  padding: 10px;

}

._quantity-wrapper svg {
  height: 20px !important;
  width: 20px !important;
  align-items: center;
  justify-content: center;
  display: flex;

}

._quantity-wrapper-cart ._quantity-wrapper {}

.no-order-yet {
  height: 100%;
}

.viewcustomer-top-card-size {
  height: 323px;
}

.filter-bar {
  justify-content: space-between !important;
}


.card-2-image .card-border {
  border: 1px solid rgba(169, 169, 169, 0.155);
  border-radius: 10px;
}

.text-font span {
  font-size: 18px !important;
}

.text-font p {
  text-align: left !important;
}

.font-big p:first-child span {
  font-size: 25px !important;
}

.blank-section {
  min-height: 90vh !important;
}

.circle-arrow .react-multi-carousel-track {
  margin-bottom: 30px;
}

.product-content p span {
  font-family: inherit !important;
  color: #212529 !important;
}


.quantity-plus-minus-button .quantity__input {
  /* width: 35px !important; */
}


.quantity-plus-minus-button ._quantity-wrapper {
  height: auto !important;
}

.quantity-plus-minus-button .quantity-checkout {
  width: auto !important;
  height: 35px !important;
  border-radius: 5px !important;
}

.quantity-plus-minus-button .checkout-delete {
  border-radius: 0 5px 5px 0 !important;
}

.quantity-plus-minus-button ._quantity-wrapper .quantity__button {
  line-height: initial !important;
  height: auto !important;
}

.product-image {
  /* height: 100px !important; */
  width: 100% !important;
}

.product-image-card {
  height: 100px !important;
}

.product-image img {
  object-fit: cover !important;
}

.border-class {
  border-radius: 10px !important;
}

/* 
@media (max-width: 991.98px) {
  .quantity-price {
    flex-direction: column !important;
  }
} */



.shipping-address-card .Shipping-Address {
  border-top: 1px solid #dee2e6 !important;
  height: 280px !important;
}

.orderdetail-card .oderDetails {
  border-top: 1px solid #dee2e6 !important;
}


.shipping-address-card .add-address-button button {
  display: flex !important;
  gap: 3px !important;
}


.shipping-address-card .add-address-button {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.ordersummarycard ._quantity-wrapper {
  border: 1px solid darkgray !important;
  width: fit-content !important;
  border-radius: 5px !important;
}

.ordersummarycard .checkout-delete {
  margin-left: 1px !important;
}

.ordersummarycard .quantity-checkout {
  width: 50px !important;
}


.coupancard-holi .badge {
  color: #212529 !important;
  border: 2px dashed darkgray;
  border-radius: 5px !important;
}


.shippingaddresscard .Shipping-Address {
  border-top: 1px solid #dee2e6 !important;
  /* min-height: 457px !important; */
}

.continueshoppingbutton .change-addressButton {
  background: var(--btn-bg-color) !important;
  color: #fff !important;
  border-radius: 5px !important;
  padding: 8px 10px !important;
}


.orderdetail-card .oderDetails {
  border: 1px solid #dee2e6 !important;
}


.orderdeatils-payment .oderDetails {
  border: 1px solid #dee2e6 !important;
  /* height: 280px !important; */
}

.emptycartimage img {
  height: 100px !important;
}


.selectaddressbutton {
  display: flex !important;
  align-items: end !important;
  justify-content: end !important;

}

.selectaddressbutton button {
  background: var(--btn-bg-color) !important;
  color: #fff !important;
}

.account-logout .dropdown-menu[data-bs-popper] {

  top: 100%;
  left: -120px !important;
  margin-top: var(--bs-dropdown-spacer);
}


.addaddress-icon-card {
  display: flex;
  gap: 4px;
  background: var(--btn-bg-color) !important;
  color: #fff;
  border-radius: 5px;
  padding: 8px 10px;
}

.icon-address {
  display: flex;
}


.margin-nav #basic-nav-dropdown {
  margin-right: -10px !important;
}

.dropdown-toggle::after {
  display: none !important;
}


.shoppingcartborder {
  border-bottom: 1px solid #dee2e6;
}

.shoppingcartborder:last-child {
  border-bottom: none !important;
}

.apply-color {
  background-color: #6c757d !important;
  color: #fff !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #6c757d !important;
}

.S9gUrf-YoZ4jf {
  display: flex;
  justify-content: center;
}

@media (max-width: 991.98px) {
  .react-multi-carousel-track {
    margin-bottom: 40px !important;
  }

  .blank-section {
    min-height: 90vh !important;
  }

  /* 
  .shop-btn {
    width: 100% !important;
  } */

  .product-addtocart .shop-btn {
    width: 100% !important;
  }

  .ordersummarycard ._quantity-wrapper {
    width: auto !important;
  }


  .arrow-remove .react-multiple-carousel__arrow--left {
    display: none !important;
  }

  .arrow-remove .react-multiple-carousel__arrow--right {
    display: none !important;
  }

  .qauntity-full-width,
  .quntity-full,
  .pricee {
    width: 100% !important;
  }

  .qauntity-full-width .quantityy {
    justify-content: space-between !important;
  }

  .qauntity-full-width .quantity-checkout {
    width: auto !important;
  }

  .quantity-plus-minus-button .quantity-checkout {
    width: 70% !important;
    height: 35px !important;
    border-radius: 5px !important;
  }

}

@media (max-width:568px) {
  .qauntity-full-width .checkout-delete {
    padding: 7.7px 8px;
  }
}
.css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
  margin: 20px 0 0px 0 !important;
}
.faqmalhua .Collapsible__trigger {
  padding: 10px;
  border: 1px solid #ebebeb;
  width: 100%;
  display: block;
  color: #20395c !important;
  font-weight: 400 !important;
}
.faqmalhua .Collapsible__contentOuter p {
  padding: 10px;
  border: 1px solid #ebebeb;
  width: 100%;
  color: #20395c !important;
  font-weight: 400 !important;
}
.faqmalhua .is-closed {
  color: #20395c !important;
  font-weight: 600 !important;
}
.faqmalhua .is-open {
  color: #20395c !important;
  font-weight: 600 !important;
}
.faqmalhua .Collapsible {
  margin-bottom: 20px !important;
}
.Faqtopic {
  font-size: 20px !important;
}
.faqanswermalhua {
  color: #20395c !important;
  font-weight: 400 !important;
}
.fontacco {
  font-weight: 600;
  color: #20395c !important;
}
.fontacco1 {
  color: #20395c !important;
  margin-left: 10px;
}

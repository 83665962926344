.left-con-login {
    background-color: rgb(255, 255, 255);
    color: rgb(33, 43, 54);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-image: none;
    overflow: hidden;
    z-index: 0;
    position: relative;
    border-radius: 16px;
    box-shadow: rgb(145 158 171 / 48%) 0px 0px 1px 0px,
        rgb(145 158 171 / 24%) 0px 2px 4px -1px;
    width: 100%;
    max-width: 460px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.left-con-login h3 {
    margin: 80px 0px 40px;
    font-weight: 700;
    line-height: 1.5;
    font-size: 1.5rem;
    font-family: var(--theme-font);
    padding-left: 40px;
    padding-right: 40px;
}

.left-mg-log img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.right-col-login {
    max-width: 480px;
    margin: auto;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 96px 0px;
}

.right-col-login-div h4 {
    font-size: 1.5rem;
    margin: 0px 0px 8px;
    font-weight: 700;
    line-height: 1.5;
}

.right-col-login-div p {
    margin: 0;
    line-height: 1.5;
    font-size: 1rem;
    font-weight: 400;
    color: #637381;
}

.text-field {
    display: flex;
    flex-direction: column;
    /* padding: 20px 0px; */
}

.text-field-input {
    margin: 10px 0px !important;
    width: 100%;
}

.flex-label-login {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.btn-login-modal {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    font-weight: 700;
    line-height: 1.71429;
    font-size: 0.9375rem;
    text-transform: capitalize;
    min-width: 64px;
    padding: 0px 22px !important;
    border-radius: 4px !important;
    color: rgb(255, 255, 255);
    background-color: #162950 !important;
    width: 100%;
    box-shadow: rgb(32 101 209 / 24%) 0px 8px 16px 0px !important;
    height: 40px !important;
    border-radius: 5px !important;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
}

.head {
    display: flex;
    position: fixed;
    justify-content: space-between;
    align-items: center;
    padding: 40px 40px;
    z-index: 1;
}

.link-register p {
    margin: 0px;
    line-height: 1.57143;
    font-size: 0.875rem;
    font-weight: 400;
}

.link-register a {
    margin: 0px;
    font-weight: 600;
    line-height: 1.57143;
    font-size: 0.875rem;
    text-decoration: none;
    color: rgb(32, 101, 209);
}

.desktop {
    display: block !important;
}

.head p {
    display: block !important;
}

.mobile-link-register {
    display: none !important;
}

.but-width-form {
    width: auto;
}

.card-vahani {
    box-shadow: 3px 3px 10px rgb(162 169 204);
    overflow-y: auto;
}

img.login-logo {
    margin: 10px 0 15px 0;
    width: 100px;
}

@media (max-width: 450px) {
    .desktop {
        display: none !important;
    }

    .head p {
        display: none !important;
    }

    .mobile-link-register {
        display: block !important;
    }

    .link-register-mobile {
        text-align: center;
        padding: 10px 0px;
    }

    .link-register-mobile p {
        margin: 0px;
        line-height: 1.57143;
        font-size: 0.875rem;
        font-weight: 400;
    }

    .link-register-mobile a {
        margin: 0px;
        font-weight: 600;
        line-height: 1.57143;
        font-size: 0.875rem;
        text-decoration: none;
        color: rgb(32, 101, 209);
    }

    .head {
        padding: 40px 10px !important;
    }

    .login-logo {
        width: 150px;
        height: auto;
    }
}

.register {
    margin-top: 10px;
    display: flex;
    float: right;
}

.buttoncolor {
    background-color: #162950 !important;
}

.hyperlink {
    color: var(--theme-color-hyperlink);
}
.testimonial-two-img {
    height: 400px;
}

.testimonial-two-img img {
    border-radius: 10px;
}

.testimonial-two-img1 {
    height: 450px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 10px !important;
}

.testimonial-two-img2 {
    height: 450px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 10px !important;
}

.main-testimonial-img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.testimonial-user {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 30px;
}

.testimonial-user img {
    border-radius: 50%;
    border: 1px solid var(--content-font);
}

.content-box-testimonial {
    border-radius: 10px !important;
    background: rgb(56 55 55 / 39%);
    backdrop-filter: blur(3.2px);
    -webkit-backdrop-filter: blur(3.2px);
    border: 1px solid rgba(56, 55, 55, 0.23);
    height: 50%;
    align-items: center !important;
    display: flex !important;
    justify-content: center !important;
}

.testimonial-quote {
    font-size: 40px !important;
    left: 5px !important;
    opacity: 0.1;
    opacity: 0.8;
    color: var(--text-white);
}

.testimonial-h2-content {
    color: var(--heading-font-color) !important;
}

.testimonial-h3-content,
.testimonial-h4-content,
.testimonial-p-conten {
    color: var(--subheading-font-color) !important;
}


.testimonial-content {
    color: var(--text-white) !important;
}


/* .testimonial-content {
    height: 110px;
} */

.testimonial-craousel-arrow .react-multiple-carousel__arrow--left,
.testimonial-craousel-arrow .react-multiple-carousel__arrow--right {
    display: none !important;
}

.react-multi-carousel-dot button {
    width: 20px !important;
    height: 20px !important;
    background-color: transparent !important;
    border-color: darkgrey !important;
    position: relative;
}



.react-multi-carousel-dot button::before {
    content: "";
    position: absolute;
    height: 10px;
    width: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}

.react-multi-carousel-dot--active button::before {
    background-color: darkgray;
}

@media (max-width:500px) and (min-width:360px) {
    /* .testimonial-content {
        height: 120px !important;
    } */

    .content-box-testimonial {
        width: 95% !important;
    }

    .testimonial-two-img1 {
        height: 400px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        border-radius: 10px !important;
    }

    .testimonial-two-img2 {
        height: 400px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        border-radius: 10px !important;
    }

    .carousel-container {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

@media (max-width:360px) {
    /* .testimonial-content {
        height: 150px !important;
    } */

    .content-box-testimonial {
        width: 95% !important;
    }
}
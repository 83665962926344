.mrp-price {
  position: relative;
}

.mrp-price:after {
  border-top: 2px solid;
  position: absolute;
  content: "";
  right: 0;
  top: 60%;
  left: 0;
}

.btn-theme.btn-theme {
  background-color: var(--color-theme-atc);
  color: var(--color-white);
  border-color: var(--color-theme-atc);
  font-weight: bold;
}

.out-of-stock-btn {
  background-color: var(--out-of-stock-btn-bg-color) !important;
  color: var(--out-of-stock-color) !important;
  border: 1px solid var(--out-of-stock-border-color) !important;
  font-weight: bold;
}

.btn-theme.btn-theme:hover {
  background-color: var(--color-theme-dark);
  color: var(--color-white);
  border-color: var(--color-theme-dark);
  box-shadow: none;
}

.btn-theme:focus {
  background-color: var(--color-theme);
  color: var(--color-white);
  border-color: var(--color-theme);
  box-shadow: none;
}

.cursor-pointer {
  cursor: pointer;
}

.react-multiple-carousel__arrow {
  z-index: 99 !important;
}

.product-variant-slider {
  justify-content: flex-start;
}

.react-multi-carousel-list {
  justify-content: center;
}

.react-multiple-carousel__arrow--right {
  top: 25%;
}

.react-multiple-carousel__arrow--left {
  top: 25%;
}

.product-image {
  height: 325px;
}

.product-shop-image {
  height: 325px !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid #e6dee13b !important;
  border-bottom: 0 !important;
}

.product-image-skeleton {
  height: 325px !important;
}

.payment-fail-image {
  width: 35%;
}

.about-inner-page p span img {
  width: 100%;
  object-fit: contain;
  height: 100%;
}

.contact-links a {
  text-decoration: none;
  color: var(--color-theme);
}

.quantity-border {
  border: 1px solid darkgray;
  width: fit-content;
  border-radius: 4px;
  overflow: hidden;
}

@media (max-width:568px) {

  .react-multiple-carousel__arrow--right,
  .react-multiple-carousel__arrow--left {
    top: 43%;
    transform: translateY(-43%);
  }
}
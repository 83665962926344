body {
  background: #fff;
}
.usercard {
  padding: 20px;
}

.cards {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background: #fff;
  padding: 10px;
  margin: 10px;
}

.checkout-bradcrumb a {
  text-decoration: none !important;
}

.product-thumbnail__image {
  width: 50px;
}

.product-thumbnail__quantity {
  font-size: 0.8571428571em;
  font-weight: 500;
  line-height: 1.75em;
  white-space: nowrap;
  text-align: center;
  border-radius: 1.75em;
  background-color: hsla(0, 0%, 44.7%, 0.9);
  color: #fff;
  box-sizing: border-box;
  min-width: 1.75em;
  height: 1.75em;
  padding: 0 0.5833333333em;
  position: absolute;
  /* top: -14px; */
  bottom: 57px !important;
  right: 87px !important;
}

.product-thumbnail {
  position: relative !important;
}

.checkoutimgitem {
  padding: 15px !important;
}

.content-box {
  background: #fff;
  background-clip: padding-box;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  color: #333333;
}

.content-box__row:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.content-box__row:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.display-table .content-box__row {
  display: table;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
}

.content-box__row--tight-spacing-vertical {
  padding-top: 0.8571428571em;
  padding-bottom: 0.8571428571em;
}

.review-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.review-block__inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 1rem;
}

.review-block__label {
  color: var(--color-dark);
  padding-right: 1.1428571429em;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 5em;
  -ms-flex: 0 1 5em;
  flex: 0 1 5em;
  font-weight: bold;
}

.review-block__content {
  -webkit-box-flex: 5;
  -webkit-flex: 5;
  -ms-flex: 5;
  flex: 5;
  color: #333333;
  padding-right: 1.1428571429em;
}

bdo {
  unicode-bidi: bidi-override;
}

.review-block__link {
  max-width: 10em;
  font-size: 0.8571428571em;
  padding: 1rem;
  text-decoration: none !important;
}

.review-block~.review-block {
  margin-top: 0.8571428571em;
  padding-top: 0.8571428571em;
  border-top: 1px solid #e6e6e6;
}

.add-check #checkbox {
  width: 15px;
}

.returntopage {
  cursor: pointer;
}

.payment_btn {
  background-color: #20395c !important;
  color: white;
  font-weight: 400;
}

.payment_btn:hover {
  color: white;
  font-weight: 400;
}

.checkout_page_product_img {
  height: 50px !important;
}

.modaladdress {
  filter: blur(2px) opacity(40%) !important;
}

.save_address {
  background-color: #20395c !important;
}

.address_card {
  margin-top: 100px !important;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.pro_img_cartpage1 {
  height: 50px !important;
}

.addressbox2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}

.productinfolist {
  width: 150px;
  height: 165px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-bottom: 10px;
  overflow: hidden;
}

.itemdetailsname {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}

.pro-img {
  overflow: hidden;
}

.pro-img img {
  height: 50px !important;
  width: 135px !important;
}

.ditails-font {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0px;
}

.orderdetailssummery {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.ditails-font1 {
  margin-bottom: 0px;
  font-size: 13px;
}

.product-thumbnail__image1 {
  width: 125px;
}

.checkout_page_product_img1 {
  height: 81px !important;
  width: 151px;
}

.product-thumbnail__quantity {
  bottom: 40px;
  right: 77px;
}

.product-thumbnail__quantity1 {
  position: absolute;
  bottom: -75px;
  right: 5px;
}

.deletebutton {
  color: rgb(253, 78, 78) !important;
}

.orderdetailssummery a {
  text-decoration: none;
}

.changeAddress-button {
  margin-top: 10px;
  /* text-align: end; */
}

.oderDetails {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
  border-top: 10px solid var(--color-theme);
  border-radius: 10px;
}

.border-bottom {
  border-bottom: 1px solid #162950;
}

.progressbar li {
  position: relative;
  list-style-type: none;
}

.addressCard p {
  margin-bottom: 0.5rem !important;
}

.addressCard p:last-child {
  margin-bottom: 0rem !important;
}

.oderDetails .line-height {
  line-height: 35px;
}

.oderDetails .orderDetailsBottom {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
  background-color: var(--theme-color-orange);
  color: white;
  border-radius: 10px;
}

._quantity-wrapper {
  width: 100%;
  position: relative;
  /* height: 40px; */
  display: flex;
  /* border-radius: 4px; */
  justify-content: flex-start;
  align-items: center;
}

.heading-font {
  font-weight: 600;
  line-height: 35px;
}

.apply-coupons {
  border-top: 1px solid #162950;
  background-color: #16295014;
}

.billing-address-add-btn {
  border: 1px solid #162950 !important;
  background: white !important;
  color: #162950;
  border-color: #162950 !important;
}

.billing-address-add-btn.btn.btn-primary {
  border-color: #162950 !important;
}

.billing-address-add-btn.btn.btn-primary:hover {
  color: #162950 !important;
}

.accordion .accordion-button {
  display: flex;
  justify-content: space-between;
}

.coupan-card {
  line-height: 23px;
}

.offcanvas ._quantity-wrapper .quantity__button {
  width: 50px;
  /* border-radius: 8px; */
  border-color: darkgrey;
  /* line-height: 0;
  height: 25px; */
  /* line-height: 25px; */
  /* height: 27px; */
  border-radius: unset;
  border: none;
}

.react-multi-carousel-list ._quantity-wrapper .quantity__button {
  width: 50px;
  border-radius: 8px;
  border-color: darkgrey;
  line-height: 0;
  height: 25px;
}

.checkout-accordian .cart-item__quantity {
  /* border-width: 0; */
  border: none;
}

.checkout-delete .Remove-1 {
  border-radius: 8px;
  border-color: darkgrey;
  border: 1px solid !important;
  padding: 5px;
  background-color: #e9ecef;
}

.address-active {
  border-color: green;
  background-color: #d9d9d940;
}

.Shipping-Address {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
  border-top: 10px solid var(--color-theme);
  border-radius: 10px;
}

.mobile-icon {
  width: 20px;
}

.offcanvas .quantity-checkout {
  position: relative;
  height: 28px;
  display: flex;
  width: 100px;
  border: 1px solid #f0f0f0;
}

.react-multi-carousel-list .quantity-checkout {
  position: relative;
  height: 28px;
  display: flex;
  width: 100px;
}

.quantity-checkout {
  position: relative;
  height: 28px;
  display: flex;
  /* width: 60px; */
  width: 100px;
}

.address-offcanvas {
  width: 40% !important;
}

.product-detail .accordion-header .accordion-button {
  padding-left: auto;
}

.form-check-input {
  cursor: pointer;
}

.variant-attributes {
  font-size: 12px;
  font-weight: 400;
  color: #000;
}

.apply-coupon-crousal.apply-coupon-crousal {
  padding-bottom: 0 !important;
}

.main-title {
  color: var(--color-dark);
}

.accordion-item.accordion-item .accordion-button {
  background: var(--color-theme);
  border-radius: 5px;
  color: var(--theme-color-dark);
  font-weight: 800;
  margin-top: 10px;
}

@media (min-width: 354px) and (max-width: 500px) {
  .address-offcanvas {
    width: 100% !important;
  }
}
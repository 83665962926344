.optional-banner {
  position: absolute;
  width: 50%;
  top: 0;
  right: 0;
  height: 360px;
}


.home-shop-carousel img {
  aspect-ratio: 1/1;
  object-fit: cover;
}

@media screen and (min-width: 992px) {
  .home-shop-carousel {
    justify-content: center !important;
  }
}

@media screen and (max-width: 768px) {
  .optional-banner {
    position: initial;
    width: 100%;
    height: auto;
  }
}

/* category arrow */
.shopcategory-arrow .react-multiple-carousel__arrow {
  display: none !important;
}

.common-border-radious ul li div a .bg-light {
  border-radius: 10px !important;
}

.category-h2-content {
  color: var(--heading-font-color) !important;
}

.category-h3-content,
.category-h4-content {
  color: var(--subheading-font-color) !important;
}

.category-p-content {
  color: var(--content-font) !important;
}
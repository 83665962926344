.hero-parallax {
  min-height: 200px;
  /* background-image: url(../../assets/images/hero-pattern.jpg); */
  /* background: linear-gradient(to right, rgb(0 0 0 / 28%), #808080b0); */
  background: var(--footer-bg-color);
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-attachment: fixed;
  /* min-height: 250px; */
  min-height: 100px;
}
.hero-parallax .main-title {
  z-index: 1;
  color: var(--text-white);
  position: relative;
}
/* .overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 0;
} */

@media (max-width:568px) {
  .hero-parallax {
    min-height: 100px;
  }
}
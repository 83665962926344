footer {
    background-color: var(--footer-bg-color);
}

.footer-social-icon ul li:nth-child(1) a:before {
    background: var(--facebook-icon-color);
}

.footer-social-icon ul li:nth-child(2) a:before {
    background-image: linear-gradient(to right, #f9ce34, #ee2a7b, #6228d7);
}

.footer-social-icon ul li a {
    width: 50px;
    height: 50px;
    background-color: var(--text-white);
    font-size: 30px !important;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    border: 3px solid var(--secondary-color);
    z-index: 1;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.footer-social-icon ul li a .icon {
    position: relative;
    color: var(--theame-color);
    transition: .5s;
    z-index: 3;
}

.footer-social-icon ul li a:hover .icon {
    color: var(--text-white);
    transform: rotateY(360deg);
}

.footer-social-icon ul li a:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    transition: .5s;
    z-index: 2;
}

.footer-social-icon ul li a:hover:before {
    top: 0;
}

.footer-menu li a {
    color: var(--text-white) !important;
    text-decoration: none !important;
    line-height: 1.9rem;
    padding-left: 5px;
}

.footer-menu-bottom li a {
    color: var(--text-white) !important;
    text-decoration: none !important;
    line-height: 1.8rem;
    margin: 15px;
}

.copy-right-foiter {
    border-top: 1px solid var(--text-white);
}

.footer-h3-heading {
    color: var(--text-white) !important;
}


/* new changes  */
.text {
    color: #fff;
    text-decoration: none;
}
.variant-thumb-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-bottom: 5px;
}

.variant-thumb {
    width: 150px;
    height: 150px;
    border-radius: 5%;
    object-fit: cover;
}

.variant-card-wrapper {
    width: 39%;
}

.variant-card {
    border: 2px solid var(--color-light);
    opacity: .8;
    transition: all ease-in-out .2s;
}

.variant-card:hover {
    border: 2px solid var(--color-theme);
    background-color: var(--color-ultralight);
    opacity: 1;
}

.variant-card.selected {
    border: 2px solid var(--color-theme);
    background-color: var(--color-ultralight);
    opacity: 1;
}

.variant-card.selected p {
    font-weight: bold;
}

.product-details .nav.nav-tabs .nav-link {
    color: var(--color-theme);
}

.product-details .nav.nav-tabs .nav-link.active {
    color: var(--color-dark);
}

.product-details .no-data {
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-details .tab-pane {
    min-height: 100px;
}

.bold-td-left {
    font-weight: 700;
}

.product-variant-slider ul li:first-child>div {
    margin-left: 0 !important;
}

.extra-small {
    font-size: .85rem;
}

h4 .rupee-icon {
    font-size: 20px;
}

h5 .rupee-icon {
    font-size: 16px;
}

.cart-btn-icon {
    font-size: 24px;
}

.image-small {
    width: 100%;
    height: 80px;
    overflow: hidden;
}

.filter-bar {
    flex: 1;
}

.filter-bar .form-control:focus,
.form-select:focus,
.form-check-input:focus {
    border-color: var(--color-theme);
    outline: 0;
    box-shadow: 0 0 0 0 rgba(13, 110, 253, .25);
}

.form-check-input:active {
    filter: brightness(100%) !important;
    border: none !important;
}

.search-icon {
    position: absolute;
    bottom: 6px;
    left: 24px;
    font-size: 24px;
    color: rgba(0, 0, 0, .5);
}

.total-products {
    display: flex;
    justify-content: center;
    align-items: center;
}

.shadow {
    box-shadow: 0 .3rem 0.5rem rgba(0, 0, 0, .15) !important;
}

.sorting-radio-button input {
    border-color: #000 !important;
}

.form-check-input:checked {
    background-color: var(--color-theme-dark)
}

.sorting-offcanvas {
    font-size: 17px !important;
}

@media screen and (max-width: 768px) {
    .product-details .nav.nav-tabs .nav-link {
        font-size: 14px;
        padding: 10px;
    }

    .variant-thumb {
        width: 100%;
    }

    .variant-card-wrapper {
        width: 45%;
    }

    .image-small {
        width: 100%;
        height: 70px;
        overflow: hidden;
    }

    .sorting-option {
        /* border: 1px solid #dbcccc; */
        /* border-radius: 5px; */
        padding: 5px;
        width: 25%;
    }
}
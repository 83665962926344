.main-banner .slick-list {
    /* height: 580px; */
}

.main-banner .ant-skeleton-image {
    height: 70vh !important;
}

/* Slider arrow */
.banner-slider-container .slick-next {
    right: 47%;
    z-index: 1000;
}

.banner-slider-container .slick-prev {
    left: 47%;
    z-index: 1000;
}

.banner-slider-container .slick-next:before {
    border: none !important;
    content: "\f105" !important;
    font-family: FontAwesome;
    vertical-align: 0 !important;
    background: var(--theame-color);
    color: var(--text-white) !important;
    padding: 5px 10px;
    border-radius: 10px;
    font-size: 30px !important;
}

.banner-slider-container .slick-prev:before {
    border: none !important;
    content: "\f104" !important;
    font-family: FontAwesome;
    vertical-align: 0 !important;
    color: var(--text-white) !important;
    background: var(--theame-color);
    padding: 5px 10px;
    border-radius: 10px;
    font-size: 30px !important;
}

.banner-slider-container .slick-prev,
.banner-slider-container .slick-next {
    bottom: 25px !important;
    top: unset !important;
}

/* ***** Banner Content ****** */
.banner-content {
    width: 30%;
    color: var(--text-white) !important;
    left: 10%;
    top: 25%;
}

.banner-h6-content {
    color: var(--text-white) !important;
}

.banner-shop-btn {
    /* background: var(--theame-color); */
    background: var(--btn-bg-color);
    color: var(--text-white) !important;
    border-radius: 10px;
    border: none !important;
}

/* **** New Banner css **** */
.banner-content h6 {
    line-height: 2rem;
}



.main-banner .slick-dots li button {
    width: 20px !important;
    height: 20px !important;
    background-color: transparent !important;
    border: 2px solid #fff !important;
    border-radius: 50%;
    position: relative;
}

.main-banner .slick-dots li button:before {
    position: absolute;
    line-height: 22px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}

.main-banner .slick-dots {
    bottom: 25px;
}

.main-banner .slick-dots li.slick-active button:before {
    color: #fff !important;
}

.main-banner .slick-dots li button:before {
    color: transparent !important;
}

@media (min-width:1130px) {
    .overlayHomeBanner {
        position: absolute !important;
        top: 0 !important;
        opacity: 0.8;
        background: linear-gradient(to right, rgb(0 0 0 / 60%) 30%, rgba(0, 0, 0, 0) 40%) !important;
        height: 100% !important;
        width: 100%;
    }
}

@media (max-width:500px) {
    .banner-content {
        display: none;
    }
}

@media (max-width:1130px) {
    .banner-content {
        width: 40%;
        color: var(--text-white) !important;
        left: 10%;
        top: 15%;
    }

    .overlayHomeBanner {
        position: absolute !important;
        top: 0 !important;
        opacity: 0.8;
        background: linear-gradient(to right, rgb(0 0 0 / 45%) 39%, rgba(0, 0, 0, 0) 55%) !important;
        height: 100% !important;
        width: 100%;
    }
}

@media (max-width:990px) {
    .banner-slider-container .slick-prev {
        left: 45% !important;
        z-index: 1000;
    }

    .banner-slider-container .slick-next {
        right: 45%;
        z-index: 1000;
    }

    .banner-content {
        width: 50%;
        color: var(--text-white) !important;
        left: 10%;
        top: 15%;
    }

    .banner-content h1 {
        font-size: 30px !important;
    }

    .banner-content h6 {
        line-height: 1.5rem !important;
        font-size: 15px !important;
    }
}

@media (max-width:767.9px) {
    .banner-content {
        display: none !important;
    }

    .banner-slider-container .slick-prev:before {
        border: none !important;
        content: "\f104" !important;
        font-family: FontAwesome;
        vertical-align: 0 !important;
        color: var(--text-white) !important;
        background: var(--theame-color);
        padding: 5px 10px;
        border-radius: 10px;
        font-size: 20px !important;
    }

    .banner-slider-container .slick-next:before {
        border: none !important;
        content: "\f105" !important;
        font-family: FontAwesome;
        vertical-align: 0 !important;
        background: var(--theame-color);
        color: var(--text-white) !important;
        padding: 5px 10px;
        border-radius: 10px;
        font-size: 20px !important;
    }
}

@media (max-width:540px) {
    .banner-slider-container .slick-prev {
        left: 38% !important;
        z-index: 1000;
    }

    .banner-slider-container .slick-next {
        right: 38%;
        z-index: 1000;
    }

    .nav-logo {
        width: 100px;
    }
}

@media (max-width:420px) {
    .nav-logo {
        width: 80px;
    }

    .phn-sm-header {
        padding: 5px 0 5px !important;
    }

    .navbar-toggler-icon {
        display: inline-block;
        width: 1.4em !important;
        height: 1.4em !important;
        vertical-align: middle;
        background-image: var(--bs-navbar-toggler-icon-bg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
    }

    .navbar-toggler {
        width: 70px !important;
        padding: 0;
        border: none;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
}
.customerhistory-icons {
  width: 10%;
}

.customer-profile-bg-color {
  background-color: var(--theme-color-dark);
}

.customerProfile-text {
  color: var(--theme-color-white);
  text-transform: capitalize;
}

.customer-details p:last-child {
  margin-bottom: 0 !important;
}

.customer-profile-address b {
  text-transform: capitalize;
}

.badge {
  background-color: var(--theme-color-orange) !important;
}

.addressCard {
  min-height: 160px;
}

.addressCard-profile {
  min-height: 230px;
  position: relative;
}

.adressCard-icons {
  position: absolute;
  right: 15px;
  bottom: 10px;
}

.adressCard-icons svg {
  vertical-align: initial;
  cursor: pointer;
}

.addressCard .card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.customerProfile-orderHistory {
  font-size: 0.8rem;
  margin: 0px;
  font-weight: 500;
  color: rgb(105, 105, 105);
  text-transform: capitalize;
}

.customer-profile-order-history {
  font-weight: 500;
  margin: 0px;
  font-size: 1.1rem;
}

.addNewAddress {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.viewcustomer-order-summary {
  min-height: 323px;
}

/* --theme-color-dark */

.service-wrapper {
  border-radius: 0.375rem;
  overflow: hidden;
}

.services a {
  height: 400px !important;
}

.fproductimage {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.cartbtn1 {
  border: none;
}

.btnplusarea {
  width: 50px !important;
}

.productimg {
  width: 306px !important;
  height: 306px !important;
  object-fit: cover;
}

.view_cart {
  background-color: #e58411 !important;
  width: 100%;
  font-weight: 600;
  color: white;
  padding: 10px 30px;
  border-radius: 10px;
}

.productimg {
  width: 100% !important;
}

.text-bottom-line {
  text-decoration: none !important;
  border-bottom: none !important;
}

.pimg a {
  text-decoration: none;
}

.fproduct a {
  text-decoration: none;
}

.ancrtag a {
  text-decoration: none !important;
  color: #20395c !important;
}

.ancrtag a:hover {
  color: #e58411 !important;
}

.services {
  border-radius: 0 0 0 0;
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.15) !important;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  z-index: 1;
}

.react-multi-carousel-list {
  display: flex;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  overflow: hidden;
  position: relative;
}

.not-available {
  width: 20%;
}

.vertical-name,
.vertical-price {
  align-items: center;
  color: #646464;
  display: flex;
  justify-content: flex-start;
  margin-top: 5px;
}
@media (max-width: 568px) {
  .checkout-page-address .shipping-address-card .Shipping-Address,
  .checkout-page-address .addressCard {
    min-height: auto;
    height: auto !important;
  }
}

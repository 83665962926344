.cart-item-name {
    color: var(--color-dark);
    text-decoration: none;
    transition: .3s ease;
}
.variant-attributes {
    color: #000;
    font-size: 12px;
    font-weight: 400;
}
.cart-footer {
    margin: 0;
}
.cart-footer {
    text-align: right;
}
.cart-ctas {
    align-items: center;
    display: flex;
    gap: 10px;
    justify-content: flex-end;
}
.totals {
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    gap: 10px;
}
.totals-subtotal-value {
    color: #000;
    font-size: 21.98px!important;
    font-weight: 700;
    margin-bottom: 0;
}
.tax-note {
    display: block;
    font-size: 12px;
    margin-bottom: 15px;
    margin-top: 15px;
}
.sticky-bottom {
    background-color: var(--color-white);
    bottom: 0;
    box-shadow: 0 -5px 25px rgb(0 0 0/5%);
    padding-bottom: 10px;
    padding-right: 15px;
    position: -webkit-sticky;
    position: sticky;
    z-index: 1020;
}
.totals-subtotal {
    font-size: 16.1px;
    font-weight: 700;
    margin-bottom: 0;
}
.cart-item-name {
    color: var(--color-dark);
    text-decoration: none;
    transition: .3s ease;
}
.bg-navbar {
    background-color: var(--text-white);
}

.nav-link,
.nav-item {
    margin-right: 10px !important;
    color: var(--theame-color);
}

.nav-logo {
    width: 150px;
}

.nav-item .nav-link {
    margin-right: 0px;
}

.nav-item.dropdown:hover .dropdown-menu {
    display: block;
}

.nav-user {
    width: auto;
    margin-left: 1rem;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--text-white);
    border-radius: 50%;
}

.nav-cart {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--text-white);
    border-radius: 50%;
    position: relative;
    cursor: pointer;
}

.nav-cart .cart-badge {
    position: absolute;
    right: -2px;
    top: -2px;
    background-color: var(--theame-color);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    border: 1px solid var(--theame-color);
    color: var(--text-white);
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}

.nav-ecom svg {
    font-size: 25px;
    color: var(--theame-color);
}

.user-profile-options {
    background-color: var(--text-white);
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.175);
    padding: 10px;
    position: absolute;
    z-index: 9999;
    right: 0;
    min-width: 180px;
}

.user-profile-options {
    padding: 5px;
}

.user-profile-options a {
    text-decoration: none;
    color: var(--color-dark);
    transition: all ease-in-out .0s;
    border-radius: 5px;
    padding: 5px 10px;
    display: block;
}

.user-profile-options a:hover {
    text-decoration: none;
    color: var(--color-dark);
    background-color: var(--color-light);
}

@media (min-width:1180px) {
    .nav-search-width {
        width: 25% !important;
    }
}

@media (min-width:992px) and (max-width:1180px) {
    .nav-search-width {
        width: 35% !important;
    }
}

@media screen and (max-width: 992px) {
    .nav-ecom {
        width: 100px;
    }

    .social-ul-nav {
        width: 400px;
    }

    .logo {
        width: 150px;
        height: auto;
    }

    .navbar-toggler:focus {
        text-decoration: none;
        outline: 0;
        box-shadow: none;
    }

    .navbar-toggler {
        width: 100px;
        padding: 0;
        border: none;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .nav-link {
        font-size: 20px;
        margin-bottom: 0;
    }

    .nav-link,
    .nav-item {
        margin-right: 0px !important;
        color: var(--color-dark);
    }

    .navbar-menu-list a {
        font-size: 18px !important;
        font-weight: 500 !important;
        padding-bottom: 0.1rem !important;
    }
    .navbar-offcanvas{
        width: 80% !important;
    }
}

/* For Cart Design */
.cart-item-name {
    color: var(--color-dark);
    text-decoration: none;
    transition: .3s ease;
    margin-bottom: 0.25rem;
    display: inline-block;
}

.cart-ctas {
    align-items: center;
    display: flex;
    gap: 10px;
    justify-content: flex-end;
}

.totals {
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    gap: 10px;
}

.totals-subtotal-value {
    color: #000;
    font-size: 21.98px !important;
    font-weight: 700;
    margin-bottom: 0;
}

.tax-note {
    display: block;
    font-size: 12px;
    margin-bottom: 15px;
    margin-top: 15px;
}

.sticky-bottom {
    background-color: var(--text-white);
    bottom: 0;
    box-shadow: 0 -5px 25px rgb(0 0 0/5%);
    padding-bottom: 10px;
    padding-right: 15px;
    position: -webkit-sticky;
    position: sticky;
    z-index: 1020;
}

.totals-subtotal {
    font-size: 16.1px;
    font-weight: 700;
    margin-bottom: 0;
}

.search-icon-nav {
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.6;
}

.nav-search-input {
    transform: translateY(-4px) !important;
}

.nav-search-input .ant-select-selector,
.nav-search-input:focus {
    color: var(--theame-color) !important;
    border-color: var(--theame-color) !important;
    outline: 0;
    box-shadow: none !important;
    border-radius: 10px !important;
    padding: 20px 10px !important;
}

.nav-search-input .ant-select-selection-placeholder {
    font-size: 18px !important;
    font-weight: 500 !important;
    color: var(--content-font) !important;
    opacity: 0.4;
}

.nav-search-input .ant-select-selector {
    padding: 20px 10px;
}

.nav-search-input .ant-select-arrow {
    display: none !important;
}

@media (max-width:400px) {
    .nav-ecom svg {
        font-size: 22px !important;
        color: var(--theame-color);
    }
}
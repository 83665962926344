.product-variant-wrapper {
  display: flex;
  margin-top: 10px;
  gap: 10px;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.item_img1 {
  height: 100px !important;
}

.item_img1 img {
  height: 100% !important;
}

.item_img11 {
  width: 520px !important;
  height: 520px !important;
}

.sku {
  font-size: 14px !important;
}

.variant-card {
  border: none !important;
  text-align: left;
}

.variant-card:hover {
  background: none !important;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .item_img11 {
    height: 350px !important;
  }
}
.product-detail-addtocard .quantity-checkout{
  width: auto;
}
.product-detail-addtocard .quantity__input {
  width: 40px !important;
}
@media (max-width: 992px) {
  .product-detail-addtocard .qauntity-full-width,.product-detail-addtocard .quntity-full,.product-detail-addtocard .pricee{
    width: auto !important;
  }
  
  .product-detail-addtocard .checkout-delete{
    margin-left: 0 !important;
  }

}
@media (max-width: 767px) {
  .item_img11 {
    height: 350px !important;
  }
}

/* ***** Product detail content ***** */
.product-short-dec p span {
  font-size: 18px !important;
}

.product-short-dec p {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.product-brand-details {
  border-top: 4px solid var(--content-font) !important;
}
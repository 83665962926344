.homepro-name {
    height: 400px;
}

.shop-btn {
    background: var(--btn-bg-color) !important;
    color: var(--text-white) !important;
    /* border-radius: 10px !important; */
    border-radius: 5px !important;
    border: none !important;
    width: fit-content !important;
    padding: 6px 10cpx !important;
}

.shop-btn.w-100 {
    width: 100% !important;
}

.product-h3-content {
    color: var(--subheading-font-color) !important;
}

.product-p-shopnow {
    color: var(--text-white);
    font-weight: 600;
}

.product-p-content {
    color: var(--content-font) !important;
}
.rounded {
    border-radius: 5px;
    overflow: hidden;
}
.roundedCircle {
    border-radius: 50%;
    overflow: hidden;
    height: auto;
}

/*section-style-1*/
.section-style-1 .main-text {
    margin-bottom: .5rem;
}
.section-style-1 .main-sub-text {
    font-style: italic;
}

/*section-style-2*/
.section-style-2 .main-text {
    margin-bottom: .5rem;
}
.section-style-2 .main-sub-text {
    font-style: italic;
}

.services-rounded .title {
    font-size: 16px;
}

.category-name {
    font-size: 12px;
}

@media screen and (max-width: 768px) {
   .services-rounded .title {
    font-size: 28px;
} 
}